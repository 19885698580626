import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import styled from "styled-components"

const DivImage = styled.div`
	@media (max-width: 996px) {
		width: 338px;
	}
`

const ImagemPainelSolar = () => {
	return (
		<DivImage>
			<PainelSolar alt="Imagem de um painel solar" />
		</DivImage>
	)
}

const PainelSolar = ({ alt }) => {
	const data = useStaticQuery(graphql`
		query {
			painel: file(relativePath: { eq: "painel-solar.png" }) {
				childImageSharp {
					fluid(quality: 90, maxWidth: 1920) {
						...GatsbyImageSharpFluid_withWebp_tracedSVG
					}
				}
			}
		}
	`)

	return <Img fluid={data.painel.childImageSharp.fluid} alt={alt} />
}
export default ImagemPainelSolar
