import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import styled from "styled-components"

const DivImage = styled.div`
	@media (max-width: 996px) {
		width: 270px;
		margin: auto;
	}
`

const ImagemLampada = () => {
	return (
		<DivImage>
			<Lampada alt="Imagem de uma lampada" />
		</DivImage>
	)
}

const Lampada = ({ alt }) => {
	const data = useStaticQuery(graphql`
		query {
			lampada: file(relativePath: { eq: "lampada.png" }) {
				childImageSharp {
					fluid(quality: 90, maxWidth: 1920) {
						...GatsbyImageSharpFluid_withWebp_tracedSVG
					}
				}
			}
		}
	`)

	return <Img fluid={data.lampada.childImageSharp.fluid} alt={alt} />
}
export default ImagemLampada
