import React from "react"

import styled from "styled-components"
import { Col, Container, Row } from "react-bootstrap"
import ImagemPainelSolar from "./imagens/painel-solar"
import ImagemCelular from "./imagens/celular"
import ImagemLampada from "./imagens/lampada"
import SlideUpAnimation from "../../animations/slide-up"

const CustomSection = styled.section`
	margin-top: -5px;
	background: rgb(255, 255, 255);
	overflow: hidden;
	padding-bottom: 150px;

	@media (max-width: 996px) {
		padding-bottom: 70px;
	}
`

const CustomContainer = styled(Container)`
	padding-top: 5px;
`

const BgDiv = styled.div`
	background: linear-gradient(
		-8deg,
		rgba(255, 255, 255, 1) 14.8%,
		rgba(239, 241, 243, 1) 15%,
		rgba(239, 241, 243, 1) 85%,
		rgba(255, 255, 255, 1) 85.2%
	);
	height: 1570px;
	width: 100%;
	position: absolute;

	@media (max-width: 996px) {
		background: linear-gradient(
			-16deg,
			rgba(255, 255, 255, 1) 14.8%,
			rgba(239, 241, 243, 1) 15%,
			rgba(239, 241, 243, 1) 85%,
			rgba(255, 255, 255, 1) 85.2%
		);
		height: 2570px;
		width: 100%;
		margin-top: 180px;
	}
`

const Card = styled.div`
	width: 350px;
	height: 315px;
	background-color: var(--white);
	box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
	display: flex;
	justify-content: center;
	transition: transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);

	&:hover {
		transform: scale(0.9);
	}

	@media (max-width: 1200px) {
		width: 300px;
	}

	@media (max-width: 996px) {
		margin: auto;
		margin-bottom: 80px;
		width: 337px;
		height: 275px;
	}
`

const DivTextoCard = styled.div`
	height: auto;
	width: 250px;
	margin: auto;
	display: flex;
	flex-direction: column;
	text-align: center;
	justify-content: center;
	align-items: center;

	p {
		font-weight: 500;
	}
`

const Titulo1 = styled.h2`
	font-weight: 500;
	font-size: 100px;
	color: var(--secondary);
	margin-bottom: 0;
`

const Span1 = styled.span`
	font-size: 42px;
	font-family: "Roboto";
	font-weight: 500;
	color: var(--secondary);
	text-transform: uppercase;
	margin-top: -25px;
`

const Titulo2 = styled.h2`
	font-weight: 500;
	font-size: 150px;
	color: var(--secondary);
	margin-bottom: -5px;

	@media (max-width: 996px) {
		font-size: 100px;
	}
`

const Span2 = styled.span`
	font-size: 70px;
	margin-left: -10px;
	font-family: "Roboto";
	font-weight: 500;
	color: var(--secondary);
	text-transform: uppercase;

	@media (max-width: 996px) {
		font-size: 50px;
		margin-left: -5px;
	}
`

const CustomRow = styled(Row)`
	padding-top: 215px;

	@media (max-width: 996px) {
		padding-top: 110px;
	}
`

const ColImagem = styled(Col)`
	@media (max-width: 996px) {
		margin-top: 30px;
	}
`

const DivTexto = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
`

const ComoFuncionaSection = () => (
	<CustomSection>
		<BgDiv />
		<CustomContainer>
			<Row>
				<Col sm="12" lg="4" className="order-lg-0">
					<Card {...SlideUpAnimation}>
						<DivTextoCard>
							<Titulo1>20</Titulo1>
							<Span1>anos</Span1>
							<p>Nossos produtos tem garantia de até 20 anos </p>
						</DivTextoCard>
					</Card>
				</Col>
				<Col sm="12" lg="4" className="order-lg-1">
					<Card {...SlideUpAnimation} data-sal-delay="100">
						<DivTextoCard>
							<Titulo2>
								8<Span2>%</Span2>{" "}
							</Titulo2>
							<p>
								Imóveis com energia solar são valorizados em
								média 8%
							</p>
						</DivTextoCard>
					</Card>
				</Col>
				<Col sm="12" lg="4" className="order-lg-2">
					<Card {...SlideUpAnimation} data-sal-delay="200">
						<DivTextoCard>
							<Titulo1>05</Titulo1>
							<Span1>anos</Span1>
							<p>
								É o tempo médio para o retorno de investimento
							</p>
						</DivTextoCard>
					</Card>
				</Col>
			</Row>
			<CustomRow
				id="comoFunciona"
				{...SlideUpAnimation}
				data-sal-delay="100"
			>
				<Col lg="7" className="order-lg-4">
					<DivTexto>
						<h2>
							Como Funciona
							<hr />
						</h2>
						<p>
							Ao instalar um painel solar em seu imóvel você se
							torna um produtor de energia, não só para você, mas
							para todo o país.
						</p>
						<p>
							A energia gerada em excesso pelo seu imóvel é
							convertida em créditos para consumo em períodos de
							maior uso de energia, como no verão, e também
							durante a noite quando não há radiação solar. Dessa
							maneira, você sempre terá energia disponível em seu
							imóvel.
						</p>
					</DivTexto>
				</Col>
				<ColImagem lg="5" className="order-lg-3">
					<ImagemPainelSolar />
				</ColImagem>
			</CustomRow>
			<CustomRow {...SlideUpAnimation}>
				<Col lg="7" className="order-lg-5">
					<h2>
						Controle na
						<br />
						palma da sua mão
						<hr />
					</h2>
					<p>
						Acompanhado da instalação é fornecido um sistema de
						monitoramento que permite acompanhar remotamente, em
						tempo real, a geração de energia fotovoltaica do seu
						sistema.
					</p>
					<p>
						O acompanhamento pode ser realizado em seu computador ou
						celular. A Tecsolar também faz o monitoramento
						gratuitamente junto com o cliente, assim sempre que
						surgir uma dúvida sobre o funcionamento o cliente terá
						nosso suporte.
					</p>
				</Col>
				<ColImagem lg="5" className="order-lg-6">
					<ImagemCelular />
				</ColImagem>
			</CustomRow>
			<CustomRow {...SlideUpAnimation}>
				<Col lg="7" className="order-lg-8">
					<DivTexto>
						<h2>
							Energia Limpa
							<hr />
						</h2>
						<p>
							Além de econômica, a energia solar é uma fonte de
							energia inesgotável e limpa por utilizar o Sol como
							fonte de energia, portanto os impactos ao meio
							ambiente são quase inexistentes.
						</p>
						<p>
							Apesar de haver impactos na construção do painel
							fotovoltaico, durante a sua vida útil ele gera mais
							de 20 vezes a energia consumida na sua fabricação.
							Além disso, 97% dos componentes incluídos em painéis
							solares podem ser reciclados.
						</p>
					</DivTexto>
				</Col>
				<ColImagem lg="5" className="order-lg-7">
					<ImagemLampada />
				</ColImagem>
			</CustomRow>
		</CustomContainer>
	</CustomSection>
)

export default ComoFuncionaSection
