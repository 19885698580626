import React, { Component } from "react"
import styled from "styled-components"
import video from "../../assets/videos/background-video.mp4"
import { Container, Row, Col } from "react-bootstrap"
import SlideUpAnimation from "../../animations/slide-up"
import scrollTo from "gatsby-plugin-smoothscroll"
import { Link } from "react-scroll"
import calculaVh from "../calculavh"

const CustomSection = styled.section`
	height: 100vh;
	height: ${props => props.vh};
	overflow: hidden;
`

const CustomVideo = styled.div`
	position: fixed;
	z-index: -2;
	min-width: 100%;
	min-height: 100%;
	transition: 1s opacity;

	video {
		min-height: 100vh;
		height: ${props => props.vh};
		min-width: 100vw;
	}
`

const CustomContainer = styled(Container)`
	height: 50%;
	transform: translateY(70%);
	position: fixed;
	z-index: -1;

	@media (max-width: 1100px) {
		position: unset;
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
		transform: unset;
	}
`

const DivTexto = styled.div`
	margin: auto;
	width: 700px;
	color: var(--white);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	@media (max-width: 996px) {
		width: unset;
	}

	hr {
		opacity: 1;
	}

	h2 {
		color: var(--white);
		opacity: 1;
		text-align: center;

		@media (min-width: 1100px) {
			text-align: unset;
		}

		br {
			@media (min-width: 1100px) {
				display: none;
			}
		}
	}

	p {
		text-align: center;
		font-weight: 300;
		padding: 0 5px 0 5px;
		max-width: 350px;

		@media (min-width: 1100px) {
			max-width: unset;
		}

		br {
			@media (max-width: 1100px) {
				display: none;
			}
		}
	}
`

const CustomButton = styled.button`
	margin-top: 50px;

	@media (max-width: 996px) {
		margin-top: 25px;
	}
`

const DivButton = styled.div`
	margin: auto;
`

const VideoWorkaround = () => (
	<div
		dangerouslySetInnerHTML={{
			__html: `
	  <video
		muted
		autoplay
		playsinline
		loop
		src="${video}"
	  />
	`,
		}}
	/>
)

class HeroSection extends Component {
	constructor(props) {
		super(props)

		this.state = {
			sectionHeight: "100vh",
		}
	}

	componentDidMount() {
		this.setState({
			sectionHeight: calculaVh(100),
		})
	}

	render() {
		return (
			<CustomSection id="inicio">
				<CustomVideo>{VideoWorkaround()}</CustomVideo>
				<CustomContainer fluid>
					<Row>
						<Col lg="12">
							<DivTexto>
								<h2 {...SlideUpAnimation}>
									Economia e<br /> sustentabilidade
									<hr />
								</h2>
								<p {...SlideUpAnimation}>
									A Tecsolar oferece as melhores soluções em
									geração de energia solar <br />{" "}
									fotovoltaica, para você economizar seu
									dinheiro e colaborar com o meio ambiente
								</p>
							</DivTexto>
						</Col>
						<DivButton {...SlideUpAnimation} data-sal-delay="100">
							<Link onClick={() => scrollTo("#cotacao")} to="">
								<CustomButton>
									FAZER COTAÇÃO ONLINE
								</CustomButton>
							</Link>
						</DivButton>
					</Row>
				</CustomContainer>
			</CustomSection>
		)
	}
}

export default HeroSection
