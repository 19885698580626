const CalculadoraSolar = valorKw => {
	// Média mensal de consumo de energia em kw
	var MediaMensalEnergia = valorKw

	// Média diaria de energia em kw
	var MediaDiariaEnergia = MediaMensalEnergia / 30.5

	// VARIAVEIS ESTÁTICAS

	// indice Solarimétrico
	var indiceSolarimetrico = 4.2

	// potência de cada modulo(painel)
	var potenciaPainel = 390

	// INICIO DE CÁLCULOS
	// potencia total em watts
	var potenciaTotal = (MediaDiariaEnergia / indiceSolarimetrico) * 1000

	// Quantidade de placa
	var quantidadeModulos = Math.trunc(potenciaTotal / potenciaPainel)
	if (quantidadeModulos % 2 > 0) {
		quantidadeModulos--
	}

	// MicroInversor
	var quantidadeMicroinversorDeye600 = 0
	if (quantidadeModulos % 4 > 0) {
		quantidadeMicroinversorDeye600++
	} else if (quantidadeMicroinversorDeye600 < 1) {
		quantidadeMicroinversorDeye600 = null
	}

	var quantidadeMicroinversorDeye1300 = Math.trunc(quantidadeModulos / 4)

	var custoInstalacao = (quantidadeModulos * potenciaPainel) / 1000
	var custoMin
	var custoMax
	if (custoInstalacao < 4.68) {
		custoMin = "R$ 10.000"
		custoMax = "R$ 25.000"
	} else if (custoInstalacao < 7.8) {
		custoMin = "R$ 25.000"
		custoMax = "R$ 39.000"
	} else if (custoInstalacao < 10.92) {
		custoMin = "R$ 39.000"
		custoMax = "R$ 54.000"
	} else if (custoInstalacao < 14.04) {
		custoMin = "R$ 54.000"
		custoMax = "R$ 64.000"
	} else if (custoInstalacao < 18.72) {
		custoMin = "R$ 64.000"
		custoMax = "R$ 79.000"
	} else if (custoInstalacao < 26.52) {
		custoMin = "R$ 79.000"
		custoMax = "R$ 110.000"
	} else if (custoInstalacao < 34.32) {
		custoMin = "R$ 110.000"
		custoMax = "R$ 150.000"
	} else if (custoInstalacao < 70) {
		custoMin = "Precisa de visita tecnica"
	}

	return {
		quantidadeModulos,
		quantidadeMicroinversorDeye600,
		quantidadeMicroinversorDeye1300,
		totalConversor:
			quantidadeMicroinversorDeye600 + quantidadeMicroinversorDeye1300,
		custoInstalacao,
		custoMin,
		custoMax,
	}
}

export default CalculadoraSolar
