import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import styled from "styled-components"

const CustomDiv = styled.div`
	width: 230px;
	margin: auto;

	@media (max-width: 996px) {
		width: 151px;
	}
`

const ImagemCelular = () => {
	return (
		<CustomDiv>
			<TelefoneCelular alt="Imagem de um aparelho celular" />
		</CustomDiv>
	)
}

const TelefoneCelular = ({ alt }) => {
	const data = useStaticQuery(graphql`
		query {
			celular: file(relativePath: { eq: "celular.png" }) {
				childImageSharp {
					fluid(quality: 90, maxWidth: 1920) {
						...GatsbyImageSharpFluid_withWebp_tracedSVG
					}
				}
			}
		}
	`)

	return <Img fluid={data.celular.childImageSharp.fluid} alt={alt} />
}
export default ImagemCelular
