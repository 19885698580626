import React from "react"
import StyledBgSobreNos from "./backgrounds/bg-sobre-nos"
import { graphql, useStaticQuery } from "gatsby"

const SobreNosSection = () => {
	const data = useStaticQuery(graphql`
		query {
			bgSobreNos: file(relativePath: { eq: "bg-sobre-nos.jpg" }) {
				childImageSharp {
					fluid(quality: 90, maxWidth: 1920) {
						...GatsbyImageSharpFluid
					}
				}
			}
			bgMobile: file(relativePath: { eq: "bg-sobre-nos-mobile.jpg" }) {
				childImageSharp {
					fluid(quality: 90, maxWidth: 1025) {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`)

	const sources = [
		data.bgMobile.childImageSharp.fluid,
		{
			...data.bgSobreNos.childImageSharp.fluid,
			media: `(min-width: 1025px)`,
		},
	]
	return <StyledBgSobreNos img={sources} />
}

export default SobreNosSection
