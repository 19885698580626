import React from "react"

import BackgroundImage from "gatsby-background-image/index"
import { Col, Container, Row } from "react-bootstrap"
import styled from "styled-components"
import SeloSvg from "../../../assets/svg/portal-solar.svg"
import SvgFluid from "../../svg-fluid"
import SlideUpAnimation from "../../../animations/slide-up"
import LogoFronius from "../imagens/imagens-logos/fronius"
import LogoRefuSol from "../imagens/imagens-logos/refusol"
import LogoABB from "../imagens/imagens-logos/abb"
import LogoSystem from "../imagens/imagens-logos/ap-sistem"
import LogoEcoSolys from "../imagens/imagens-logos/eco-solys"
import LogoHoyMiles from "../imagens/imagens-logos/hoymiles"
import LogoDeye from "../imagens/imagens-logos/deye"
import LogoCanadianSolar from "../imagens/imagens-logos/canadian-solar"
import LogoBYD from "../imagens/imagens-logos/byd"
import LogoDAH from "../imagens/imagens-logos/dah-solar"
import LogoQC from "../imagens/imagens-logos/qcells"
import LogoTinarSolar from "../imagens/imagens-logos/trina-solar"
import LogoGloboBrasil from "../imagens/imagens-logos/globo-brasil"
import LogoLuxen from "../imagens/imagens-logos/luxen"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

const responsive = {
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 4,
		slidesToSlide: 2, // optional, default to 1.
	},
	tablet: {
		breakpoint: { max: 1024, min: 996 },
		items: 4,
		slidesToSlide: 2, // optional, default to 1.
	},
	mobile: {
		breakpoint: { max: 996, min: 0 },
		items: 1,
		slidesToSlide: 1, // optional, default to 1.
	},
}

const CustomContainer = styled(Container)`
	padding: 140px 15px 170px 15px;
`

const DivSvg = styled.div`
	height: 100%;
	display: flex;
	justify-content: center;
`

const CustomH2 = styled.h2`
	margin-bottom: 24px;

	hr {
		width: 255px !important;
	}
`

const CarouselPosition = styled.div``

const CustomCarousel = styled(Carousel)`
	.react-multiple-carousel__arrow--right {
		right: 0;
		margin-top: 90px;
	}

	.react-multiple-carousel__arrow--left {
		left: 0;
		margin-top: 90px;
	}

	button {
		padding: 0;
	}

	.react-multiple-carousel__arrow {
		background: transparent;

		::before {
			color: black;
		}
	}
`

const CustomRow = styled(Row)`
	padding-bottom: 170px;
`

const CustomCol = styled(Col)`
	height: 400px;
`

const Desktop = styled.div`
	height: 200px;
	display: flex;
	align-items: flex-end;
	justify-content: center;
`

const DesktopCima = styled.div`
	height: 200px;
	display: flex;
	align-items: flex-end;
	justify-content: center;
`

const BgSobreNos = ({ img }) => (
	<BackgroundImage
		fluid={img}
		Tag="section"
		id="sobreNos"
		backgroundColor={`#ffffff`}
	>
		<CustomContainer>
			<CustomRow {...SlideUpAnimation}>
				<Col lg="7">
					<CustomH2>
						Sobre nós
						<hr />
					</CustomH2>
					<p>
						A TecSolar é uma empresa situada em São José do Rio
						Preto que tem como objetivo prover a solução que
						permitirá você pagar o valor mínimo da conta de energia.
						Para isso, tornaremos seu imóvel integrante de outros
						milhares que consumem energia gerada por meio de fontes
						renováveis.
					</p>
					<p>
						Desse modo, minimizamos os impactos gerados pela
						necessidade do homem à energia elétrica, construindo um
						sistema microgerador que transforma energia da radiação
						solar em energia elétrica de forma simples e limpa. Tal
						solução proporciona uma valorização do imóvel e economia
						na conta de energia.
					</p>
				</Col>
				<Col lg="5">
					<DivSvg>
						<SvgFluid
							id={SeloSvg.id}
							width="300px"
							height="250px"
						/>
					</DivSvg>
				</Col>
			</CustomRow>
			<div {...SlideUpAnimation}>
				<h2>
					Marcas que
					<br /> Trabalhamos <hr />
				</h2>

				<CarouselPosition>
					<CustomCarousel
						responsive={responsive}
						infinite
						autoPlay
						autoPlaySpeed={3000}
					>
						<CustomCol>
							<DesktopCima>
								<LogoFronius />
							</DesktopCima>
							<Desktop>
								<LogoEcoSolys />
							</Desktop>
						</CustomCol>
						<CustomCol>
							<DesktopCima>
								<LogoRefuSol />
							</DesktopCima>
							<Desktop>
								<LogoHoyMiles />
							</Desktop>
						</CustomCol>
						<CustomCol>
							<DesktopCima>
								<LogoABB />
							</DesktopCima>
							<Desktop>
								<LogoDeye />
							</Desktop>
						</CustomCol>
						<CustomCol>
							<DesktopCima>
								<LogoSystem />
							</DesktopCima>
							<Desktop>
								<LogoCanadianSolar />
							</Desktop>
						</CustomCol>
						<CustomCol>
							<DesktopCima>
								<LogoBYD />
							</DesktopCima>
							<Desktop>
								<LogoGloboBrasil />
							</Desktop>
						</CustomCol>
						<CustomCol>
							<DesktopCima>
								<LogoDAH />
							</DesktopCima>
							<Desktop>
								<LogoLuxen />
							</Desktop>
						</CustomCol>
						<CustomCol>
							<DesktopCima>
								<LogoQC />
							</DesktopCima>
							<Desktop>
								<LogoTinarSolar />
							</Desktop>
						</CustomCol>
					</CustomCarousel>
				</CarouselPosition>
			</div>
		</CustomContainer>
	</BackgroundImage>
)

const StyledBgSobreNos = styled(BgSobreNos)`
	position: relative;
	min-height: 37.5rem;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
`

export default StyledBgSobreNos
