import React from "react"

import { Col, Container, Row } from "react-bootstrap"
import styled from "styled-components"
import ImagemCasa from "./imagens/casa"
import SlideUpAnimation from "../../animations/slide-up"

const CustomSection = styled.section`
	background-color: var(--white);
	padding: 180px 0;

	@media (max-width: 1100px) {
		padding: 80px 0;
	}
`

const DivImagem = styled.div`
	width: 445px;
	height: 408px;
	margin: auto;

	@media (max-width: 996px) {
		display: none;
	}
`

const EnergiaSolarSection = () => (
	<CustomSection id="vantagens">
		<Container>
			<Row {...SlideUpAnimation}>
				<Col lg="6">
					<h2>
						Energia Solar
						<br />É um investimento <hr />
					</h2>
					<p>
						A energia solar é a fonte de energia renovável mais
						barata que existe, você pode economizar até 95% na sua
						conta de luz, não vai precisar se preocupar com
						reajustes de tarifa e seu imóvel vai ser valorizado.
					</p>
					<p>
						O investimento feito para instalação de placas solares
						podem parecer elevados, porém a expectativa de retorno
						de investimento do sistema de energia solar é de
						aproximadamente 5 anos (menos de 1/4 de sua vida útil).
					</p>
					<p>
						Faça um <strong>orçamento</strong> grátis
					</p>
				</Col>
				<Col lg="6">
					<DivImagem>
						<ImagemCasa />
					</DivImagem>
				</Col>
			</Row>
		</Container>
	</CustomSection>
)
export default EnergiaSolarSection
