import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"

const ImagemCasa = () => {
	return <Casa alt="Foto de uma casa" />
}

const Casa = ({ alt }) => {
	const data = useStaticQuery(graphql`
		query {
			casa: file(relativePath: { eq: "casa.jpg" }) {
				childImageSharp {
					fluid(quality: 90, maxWidth: 1920) {
						...GatsbyImageSharpFluid_withWebp_tracedSVG
					}
				}
			}
		}
	`)

	return <Img fluid={data.casa.childImageSharp.fluid} alt={alt} />
}
export default ImagemCasa
