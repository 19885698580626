import React, { Component } from "react"

import { Col, Container, Modal, ModalBody, Row } from "react-bootstrap"
import styled from "styled-components"
import Input from "../input"
import Slider from "../slider"
import SlideUpAnimation from "../../animations/slide-up"
import CalculadoraSolar from "../calculadora-solar"
import { ReCaptcha } from "react-recaptcha-v3"
import Close from "../../assets/svg/botao-fechar.svg"
import SvgFixed from "../svg-fixed"
import Mask from "../input-mask"
const CustomSection = styled.section`
	background-color: var(--primary);
	padding: 120px 0;
`

const CustomH2 = styled.h2`
	color: var(--white);
`

const CustomP = styled.p`
	color: var(--white);
	margin-top: 16px;
`

const DivForm = styled.div`
	padding: 30px 0 0 0;
`

const DivInput = styled.div`
	padding-bottom: 16px;
`

const DivInputMobile = styled.div`
	padding-bottom: 16px;

	@media (min-width: 996px) {
		padding-bottom: unset;
	}
`

const CustomCol = styled(Col)`
	overflow: hidden;
`

const CustomForm = styled.form`
	overflow: unset;

	@media (max-width: 996px) {
		overflow: hidden;
	}
`

const CustomButton = styled.button`
	margin-top: 50px;
	margin-left: auto;

	@media (max-width: 996px) {
		margin: auto;
		margin-top: 80px;
	}
`

const CustomModal = styled(Modal)`
	height: 100%;
	display: flex !important;
	justify-content: center;
	align-items: center;

	.modal-body {
		width: 890px;
		height: 572px;

		@media (max-width: 996px) {
			width: 80%;
			margin: auto;
		}
	}

	.modal-content {
		@media (max-width: 996px) {
			width: 95%;
			margin: auto;
		}
	}

	button {
		margin-top: 40px;
	}
`

const DivTexto = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: 650px;
	margin: auto;
	color: var(--primary);
	p {
		font-weight: 300;
		text-align: center;
		text-align: center;

		@media (max-width: 996px) {
			margin: 0;
		}
	}

	span {
		color: var(--secondary);
		font-weight: 900;
		font-size: 42px;
		margin-bottom: -50px;

		@media (max-width: 996px) {
			font-size: 24px;
		}
	}

	strong {
		@media (max-width: 996px) {
			font-size: 14px;
		}
	}
`

const Custo = styled.span`
	color: var(--secondary);
	font-weight: 900;
	font-size: 32px;

	@media (max-width: 996px) {
		font-size: 20px;
	}
`

const ColModal = styled(Col)`
	margin-bottom: 30px;
	margin-top: 15px;
`

const ButtonClose = styled.div`
	position: absolute;
	top: 20px;
	right: 20px;
	cursor: pointer;

	@media (max-width: 996px) {
		right: -15px;
		top: 10px;
	}
`

const Centavos = styled.span`
	font-size: 20px !important;
	color: var(--secondary);
	font-weight: 900;
`

class FacaSuaCotacaoSection extends Component {
	constructor() {
		super()

		this.initialFormState = {
			showModal: false,

			nome: "",
			telefone: "",
			email: "",
			valorKw: 400,
			loadingSubmit: false,
		}

		this.state = { ...this.initialFormState, orcamento: null }
	}

	verifyCallback = recaptchaToken => {
		this.gRecaptchaToken = recaptchaToken
	}

	updateToken = () => {
		// you will get a new token in verifyCallback
		this.recaptcha.execute()
	}

	toggleModal = () => {
		this.setState({ showModal: !this.state.showModal })
	}

	handleInputChange = event =>
		this.setState({
			[event.target.name]: event.target.value,
		})

	handleSubmit = event => {
		event.preventDefault()

		this.updateToken()
		this.setState({ loadingSubmit: true })

		fetch(
			"https://loy6vulmcd.execute-api.us-east-2.amazonaws.com/prod/contact-form",
			{
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					email: "orcamento@tecsolar.eng.br",
					subject: `Contato de ${this.state.nome} pelo site`,
					message: `${this.state.nome} (${this.state.email}) (${this.state.telefone}) fez uma cotação para ${this.state.valorKw} KWh/mês`,
					gRecaptchaToken: this.gRecaptchaToken,
				}),
			}
		)

		this.setState({
			orcamento: CalculadoraSolar(this.state.valorKw),
			loadingSubmit: false,
			showModal: true,
		})
	}

	render() {
		return (
			<CustomSection id="cotacao">
				<Container {...SlideUpAnimation}>
					<Row>
						<CustomCol className="offset-lg-2 col-lg-8">
							<CustomH2>
								Faça sua COTAÇÃO
								<hr />
							</CustomH2>
							<CustomP>
								A estimativa de custos é personalizada para cada
								cliente baseado na média de consumo anual em
								kilowatts. Fique tranquilo, garantimos o sigilo
								de todas as informações.
							</CustomP>
							<CustomForm onSubmit={this.handleSubmit}>
								<ReCaptcha
									ref={ref => (this.recaptcha = ref)}
									sitekey="6Le9mbcZAAAAAEnVgQByb9HlS4LPTSCXxLrYDXxB"
									action="contactForm"
									verifyCallback={this.verifyCallback}
								/>
								<DivForm>
									<Row>
										<Col lg="6">
											<DivInput>
												<Input
													id="nome"
													name="nome"
													type="text"
													label="Nome"
													onChange={
														this.handleInputChange
													}
													value={this.state.nome}
												/>
											</DivInput>
										</Col>
										<Col lg="6">
											<DivInputMobile>
												<Mask
													mask="+55 (99) 99999-9999"
													id="telefone"
													name="telefone"
													type="text"
													label="Telefone"
													onChange={
														this.handleInputChange
													}
													value={this.state.telefone}
												/>
											</DivInputMobile>
										</Col>
										<Col lg="12">
											<Input
												id="email"
												name="email"
												type="email"
												label="E-mail"
												onChange={
													this.handleInputChange
												}
												value={this.state.email}
											/>
										</Col>
										<Col lg="12">
											<CustomP>
												Média de consumo anual
												(KWh/Mês):
											</CustomP>
											<Slider
												valorKw={this.state.valorKw}
												valorKwChange={valor =>
													this.setState({
														valorKw: valor,
													})
												}
											/>
										</Col>
										<CustomButton
											disabled={this.state.loadingSubmit}
										>
											{this.state.loadingSubmit
												? "Enviando..."
												: "Realizar Cotação"}
										</CustomButton>
									</Row>
								</DivForm>
							</CustomForm>
						</CustomCol>
					</Row>
				</Container>

				<CustomModal
					show={this.state.showModal}
					size={"xl"}
					className="modal fade"
					onHide={this.toggleModal}
				>
					<ModalBody>
						<DivTexto>
							<ButtonClose
								onClick={() => {
									this.toggleModal()
								}}
							>
								<SvgFixed
									id={Close.id}
									width="30px"
									height="30px"
								/>
							</ButtonClose>
							<h4>Cotação Aproximada:</h4>
							<p>
								<Custo>
									{" "}
									{this.state.orcamento &&
										this.state.orcamento.custoMin}
									{this.state.orcamento &&
									this.state.orcamento.custoMax ? (
										<Centavos>,00</Centavos>
									) : (
										""
									)}{" "}
								</Custo>
								{this.state.orcamento &&
								this.state.orcamento.custoMax ? (
									<strong>até</strong>
								) : (
									""
								)}
								<Custo>
									{" "}
									{this.state.orcamento &&
										this.state.orcamento.custoMax}
									{this.state.orcamento &&
									this.state.orcamento.custoMax ? (
										<Centavos>,00</Centavos>
									) : (
										""
									)}
								</Custo>
							</p>
							<Row>
								<ColModal>
									<p>
										{" "}
										<span>
											{" "}
											{this.state.orcamento &&
												this.state.orcamento
													.quantidadeModulos}
										</span>
										<br />
										<strong>Placas Solares</strong>
									</p>
								</ColModal>
								<ColModal>
									<p>
										<span>
											{" "}
											{this.state.orcamento &&
												this.state.orcamento
													.quantidadeMicroinversorDeye1300}
										</span>
										<br />
										<strong>
											Micro Inversores
											<br />1300
										</strong>
									</p>
								</ColModal>
								{this.state.orcamento &&
								this.state.orcamento
									.quantidadeMicroinversorDeye600 ? (
									<ColModal>
										<p>
											<span>
												{" "}
												{this.state.orcamento &&
													this.state.orcamento
														.quantidadeMicroinversorDeye600}
											</span>
											<br />
											<strong>
												Micro Inversores
												<br />600
											</strong>
										</p>
									</ColModal>
								) : (
									""
								)}
								<p>
									Este valor é apenas uma{" "}
									<strong>estimativa</strong>, para saber o
									valor exato entre em contato conosco pra
									fazer um orçamento sem compromisso.{" "}
								</p>
							</Row>
							<a
								href="https://api.whatsapp.com/send?phone=5517991564129"
								target="_blank"
								rel="noopener noreferrer"
							>
								<button>Entre em contato</button>
							</a>
						</DivTexto>
					</ModalBody>
				</CustomModal>
			</CustomSection>
		)
	}
}

export default FacaSuaCotacaoSection
