import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import styled from "styled-components"

const DivImagem = styled.div`
	width: 200px;
`

const LogoDAH = () => {
	return (
		<DivImagem>
			<Logo alt="imagem logo DAH Solar" />
		</DivImagem>
	)
}

const Logo = ({ alt }) => {
	const data = useStaticQuery(graphql`
		query {
			solarLogo: file(relativePath: { eq: "logos/dah-solar.png" }) {
				childImageSharp {
					fluid(quality: 90, maxWidth: 1920) {
						...GatsbyImageSharpFluid_withWebp_tracedSVG
					}
				}
			}
		}
	`)

	return <Img fluid={data.solarLogo.childImageSharp.fluid} alt={alt} />
}
export default LogoDAH
