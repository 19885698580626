import React, { Component } from "react"

import ReactSlider from "react-slider"
import styled from "styled-components"
import Raio from "../assets/images/raio.png"

const CustomDiv = styled.div`
	height: 50px;
	width: 104%;
	display: flex;
	align-items: center;

	@media (max-width: 996px) {
		width: 90%;
		margin-left: 20px;
	}
`

const CustomSlider = styled(ReactSlider)`
	width: 100%;
	height: 16px;

	.slider-track.slider-track-0 {
		width: 100%;
		height: 16px;
		background-color: #387938;
	}

	.slider-track.slider-track-1 {
		width: 105%;
		height: 16px;
		background-color: #1e2e45;

		@media (max-width: 996px) {
			width: auto;
		}
	}

	.slider-thumb.slider-thumb-0 {
		height: 36px;
		width: 28px;
		z-index: 1;
		transform: translate(-50%, -25%);
		background-image: url(${Raio});
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		cursor: grab;

		:focus {
			outline: none;
		}
	}
`

const Value = styled.div`
	position: absolute;
	top: 110px;
	left: 50%;
	transform: translateX(-50%);

	span {
		font-family: Roboto;
		font-size: 24px;
		font-weight: 900;
		color: var(--white);
	}
`

class Slider extends Component {
	render() {
		return (
			<CustomDiv>
				<CustomSlider
					className="horizontal-slider"
					thumbClassName="slider-thumb"
					trackClassName="slider-track"
					min={0}
					max={6000}
					step={50}
					defaultValue={this.props.valorKw}
					onChange={this.props.valorKwChange}
				/>
				<Value>
					<span>{this.props.valorKw} KWh/mês</span>
				</Value>
			</CustomDiv>
		)
	}
}

export default Slider
