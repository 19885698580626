import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import styled from "styled-components"

const DivImagem = styled.div`
	width: 164px;
`

const LogoDeye = () => {
	return (
		<DivImagem>
			<Logo alt="imagem logo Deye" />
		</DivImagem>
	)
}

const Logo = ({ alt }) => {
	const data = useStaticQuery(graphql`
		query {
			deyeLogo: file(relativePath: { eq: "logos/deye.png" }) {
				childImageSharp {
					fluid(quality: 90, maxWidth: 1920) {
						...GatsbyImageSharpFluid_withWebp_tracedSVG
					}
				}
			}
		}
	`)

	return <Img fluid={data.deyeLogo.childImageSharp.fluid} alt={alt} />
}
export default LogoDeye
