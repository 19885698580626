import React, { Component } from "react"
import InputMask from "react-input-mask"

import styled from "styled-components"

const CustomLabel = styled.label`
	position: absolute;
	top: 25%;
	left: 40px;
	transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
	z-index: 0;
	opacity: ${props => (props.active ? "0" : "1")};
	pointer-events: none;
	user-select: none;
	color: var(--white);
`

const CustomInput = styled(InputMask)`
	border: none;
	border: ${props =>
		props.active
			? "1px solid var(--secondary)"
			: "1px solid var(--secondary)"};
	box-shadow: ${props =>
		props.active
			? " 0 0 0.2em var(--warning), 0 0 0.2em var(--secondary), 0 0 0.2em var(--secondary) "
			: " none "};
	background: transparent;
	display: block;
	padding: 0;
	padding-left: 40px;
	width: 100%;
	height: 50px;
	position: relative;
	color: var(--white);
	&:focus {
		outline: none;
		color: var(--white);
		background: transparent;
	}
`

class Mask extends Component {
	constructor(props) {
		super(props)

		this.state = {
			active: false,
			focused: false,
		}
	}

	handlerFocus = () => this.setState({ active: true, focused: true })

	handlerFocusOut = e =>
		this.setState({ active: !!e.target.value, focused: false })

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.value !== this.props.value && !this.state.focused) {
			this.setState({ active: !!this.props.value })
		}
	}

	render() {
		return (
			<>
				<CustomLabel htmlFor={this.props.id} active={this.state.active}>
					{this.props.label}
				</CustomLabel>
				<CustomInput
					required
					{...this.props}
					aria-label={this.props.label}
					onFocus={this.handlerFocus}
					onBlur={this.handlerFocusOut}
					active={this.state.active}
				/>
			</>
		)
	}
}

export default Mask
