import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import HeroSection from "../components/pages/hero-section"
import EnergiaSolarSection from "../components/pages/energia-solar-section"
import ComoFuncionaSection from "../components/pages/como-funciona-section"
import SobreNosSection from "../components/pages/sobre-nos-section"
import FacaSuaCotacaoSection from "../components/pages/faca-sua-cotacao-section"

const IndexPage = () => (
	<Layout>
		<SEO title="Energia Solar" />
		<HeroSection />
		<EnergiaSolarSection />
		<ComoFuncionaSection />
		<FacaSuaCotacaoSection />
		<SobreNosSection />
	</Layout>
)

export default IndexPage
